<template>
  <div>
    <SideNav />
    <div class="flex flex-col w-full px-5 xl:px-20 prod-area pb-20 md:pb-32">
      <div class="flex top-2-area w-full mx-auto aic jcb mb-5">
        <div class="flex w-full py-5 border-b relative mt-4 lg:mt-16">
          <div class="flex aic w-full">
            <div class="flex flex-col ais jcb">
              <h2 class="w-full text-gray-800 capitalize nunitoSans-bold">Ihre Bestellung</h2>
              <p class="text-gray-500 w-full fs-12"></p>
            </div>
            <div class="absolute bottom-0 right-0 w-90p h-5p bg-primary"></div>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col mt-1 md:mt-10 space-y-20">
        <div
          class="flex flex-col w-full"
          v-for="item in pattern"
          :key="item.pattern_id"
        >
          <div class="w-full nunitoSans-bold mb-3 text-xs">
            <span class="flex aic space-x-1 text-secondary">
              <span>{{$t('from')}}</span>
              <span>{{formatDate(item.start_time)}}</span>
            </span>

          </div>
          <div class="flex aic jcb w-full mb-3 text-xs nunitoSans-bold">
            <span class="text-sm">{{item.name}}</span>
            <div class="">
              <button
                class="betrieb rounded-lg text-red-600 fcc"
                type="button"
                @click="removePattern(item.pattern_id)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="flex aic jcb w-full bg-secondary text-white px-5">
            <span class="flex aic md:w-3/12 text-xs xl:text-sm h-40p">Ihre ausgewählten Brote</span>
            <div class="hidden md:flex aic jce w-7/12 text-center ml-auto text-sm h-40p">
              <span
                class="fcc ansehen-days h-40p"
                v-for="day in completeweekdaysList"
                :key="day.id"
              >{{$t(day.name)}}</span>
            </div>
            <div class="w-2/12 text-right text-xs md:text-sm">
              Betrieb
            </div>
          </div>
          <div class="product-item w-full flex flex-col ais jcb mx-auto border-t lg:px-5 space-y-4 lg:space-y-0 bg-white">
            <div
              class="flex flex-col w-full anderen-items"
              v-for="product in item.products"
              :key="product.PatternProductId"
            >
              <div class="w-full flex aic jcb px-1 lg:px-0">
                <div class="flex aic space-x-3 h-60p">
                  <img
                    :src="
                      product.image ? storageUrl + product.image : '/img/dish.webp'
                    "
                    :alt="product.name"
                    class="ai-img"
                  >
                  <h2 class="text-xs xl:text-sm text-gray-800">{{product.name}}</h2>
                </div>
                <div class="hidden md:flex aic jce w-7/12 text-center ml-auto text-base">
                  <span
                    class="ansehen-quantity fcc fs-16 h-60p"
                    :class="[qtyPerDay(item,product.pattern)?'text-secondary':'text-gray-300']"
                    v-for="item in (1,7)"
                    :key="item"
                  >{{qtyPerDay(item,product.pattern) || '_'}}</span>
                </div>
                <div class="md:w-2/12 flex aic jce space-x-2">
                  <button
                    @click="()=>{showEditModal = true; selectedProduct = product; patternId=item.pattern_id}"
                    class="betrieb rounded-lg text-green-600 fcc"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 lg:h-5 lg:w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                  </button>
                  <button
                    class="betrieb rounded-lg text-red-600 fcc"
                    type="button"
                    @click="remove(item.pattern_id,product.RestaurantProductId)"
                  >
                    <svg
                      class="h-3 w-3"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="flex md:hidden aic jcs w-full py-1 fs-12 bg-gray-200 bg-opacity-30 shadow-md">
                <div
                  class="fncc w-1-7"
                  v-for="day in completeweekdaysList"
                  :key="day.id"
                >
                  <span>{{$t(day.name)}}</span><span class="text-primary px-1">{{qtyPerDay(day.id,product.pattern) || '_'}}</span>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <ScheduleEdit
      :showEditModal="showEditModal"
      @close="closeScheduleEdit"
      :selectedProduct="selectedProduct"
      :patternId="patternId"
      :delete="remove"
    ></ScheduleEdit>

  </div>
</template>
<script>
import SideNav from "@/components/SideNav.vue";
import ScheduleEdit from "@/components/ScheduleEdit.vue";

export default {
  components: { SideNav, ScheduleEdit },
  data() {
    return {
      showEditModal: false,
      selectedProduct: null,
      patternId: null,
    };
  },
  computed: {
    pattern() {
      return this.$store.state.subscription.pattern;
    },
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    currency() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .currency;
    },
  },
  methods: {
    closeScheduleEdit() {
      this.showEditModal = false;
      this.$store.dispatch("subscription/getPattern");
    },
    remove(patternId, productId) {
      return this.$swal({
        title: this.$t("Do you really want to delete this product?"),
        showCloseButton: true,
        showConfirmButton: true,
        showClass: {
          popup: "swal2-show md-warning",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$store
            .dispatch("subscription/deleteProductFromPattern", {
              patternId,
              productId,
            })
            .then(() => {
              if (
                !this.pattern.find((p) => p.pattern_id == patternId).products
                  .length
              ) {
                this.$store.dispatch("subscription/deletePattern", patternId);
              }
            });
        } else {
          this.$store.dispatch("subscription/getPattern");
        }
      });
    },
    removePattern(patternId) {
      return this.$swal({
        title: this.$t("Do you really want to delete this pattern?"),
        showCloseButton: true,
        showConfirmButton: true,
        showClass: {
          popup: "swal2-show md-warning",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$store.dispatch("subscription/deletePattern", patternId);
        }
      });
    },
  },
  watch: {
    pattern: {
      handler: function (val) {
        if (val.find((p) => !p.products.length)) {
          this.$store.dispatch(
            "subscription/deletePattern",
            val.find((p) => !p.products.length).pattern_id
          );
        }
      },
    },
  },
  mounted() {
    this.$store.dispatch("subscription/getPattern");
  },
};
</script>
